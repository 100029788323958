<template>
  <div class="grid grid-cols-2 x-tabs">
    <div
      class="grid place-content-center"
      :class="getSelectedTabClass('debit')"
      @click="changeTab('debit')"
    >
      <span>Debit</span>
    </div>
    <div
      class="grid place-content-center"
      :class="getSelectedTabClass('credit')"
      @click="changeTab('credit')"
    >
      <span>Credit</span>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      selectedTab: 'debit',
    }
  },
  methods: {
    getSelectedTabClass(tabId) {
      return this.selectedTab === tabId ? 'x-selected-tab-item' : 'x-tab-item'
    },
    changeTab(tabId) {
      this.selectedTab = tabId
      this.$emit(tabId, tabId)
    },
  },
}
</script>

<style lang="scss" scoped>
.x-tabs {
  height: 35px;
  padding: 5px 8px;
  border-radius: 9999px;
  background-color: #f3f5f5;
  cursor: pointer;
  text-align: center;
  transition: all 0.4s ease-in-out;
}
.x-tab-item {
  height: 25px;
  padding: 5px 20px;
  border-radius: 9999px;
  background-color: #f3f5f5;
  transition: background-color, color 0.2s ease-in-out;
}
.x-selected-tab-item {
  height: 25px;
  padding: 5px 20px;
  border-radius: 9999px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.27);
  background-color: #000000;
  color: #fff;
  transition: background-color, color 0.2s ease-in-out;
}
</style>
